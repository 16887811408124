import React from "react"
import Layout from "../../components/layout"

import newsPostStyles from "../../styles/templates/news-post.module.css"

import adFoundation from "../../images/news/article-1/foundation.png"
import cutout from "../../images/news/article-1/cutout.png"

const Article1Page = () => {
    return (
        <Layout location="news">
        <div className={ newsPostStyles.article }>
            <h1>Ad Foundation turns over comm plan to NCCA</h1>
            <h3>November 26, 2020</h3>
            <div className={ newsPostStyles.landscape }>
                <img src={adFoundation} alt=""/>
            </div>
            <p>Advertising Foundation of the Philippines, the social development institution of the Philippine marketing communications industry, completed and delivered the project "Sagisag Kultura communications program/plan and audio-visual presentation" to the National Commission on Culture & the Arts (NCCA), In photo (from left) are Grace Reytas. Section Chief of NCCA-PPFD- Cultural Dissemination; Director Nelson Cruz of ASAP and Roll, Inc.; Lissa del Valle of Noisy Neighbors, Inc.; Vince Reyes of ASAP and Executive Decisions, Inc.; NCCA Executive Director Emelita Almosara; NCCA Chairman Prof. Felipe de Leon, Jr., Jun Nicdao, Chaiman of Ad Foundation and President of the MBC), musical composer-director Roy Vincent del Valle of Noisy Neighbors & ASAP; NCCA Sub-commission for Cultural Dissemination & Executive Council member Q. Pastrana; and Ad Foundation's Assistant Board Secretary and Executive Director Linda Gamboa. “Sagisag Kultura" was undertaken by Ad Foundation as one of its partnership projects as a culture-based educational tool and nation building vehicle under one of its program committees, Special Projects.</p>
            <div className={ newsPostStyles.poster }>
                <img src={cutout} alt=""/>
            </div>
            <button onClick={() => {
                window.history.back()
            }}>Go Back</button>
        </div>
        </Layout>
    )
}

export default Article1Page;